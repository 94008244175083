<template>
  <v-container class="d-flex flex-column">
    <v-card class="elevation-0">
      <CategoryTitle :category="category" class="mt-0 mb-6 my-lg-6" />
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" sm="8">
          <v-card-text class="pa-0">
            <v-form
              v-on:submit.prevent
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <label v-html="`${$t('profile.contacts.email')} *`" />
              <v-text-field
                v-model="email"
                :placeholder="`${$t('profile.contacts.email')}`"
                required
                outlined
                dense
                clearable
                :rules="emailRules"
                @keyup.enter="handleResetPasswordRequest"
                class="mt-1"
              ></v-text-field>
            </v-form>
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4" class="px-0 px-sm-4">
          <v-card-actions class="pa-0">
            <!-- to="/register" -->
            <v-btn
              @click="handleResetPasswordRequest"
              class="reset-button"
              color="primary"
              large
              :block="$vuetify.breakpoint.xs"
              min-width="200"
              height="40"
              depressed
              :loading="loading"
              :disabled="!valid || email.length <= 0"
              >{{ $t("login.submitResetPassword") }}</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
      <div class="mt-2">
        <ResponseMessage :response="response" />
      </div>
    </v-card>
  </v-container>
</template>
<style scoped lang="scss">
label {
  font-weight: 600;
  text-transform: uppercase;
}
</style>
<script>
import aliRegistrationService from "@/service/aliRegistrationService";
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";
import {
  requiredValue,
  isEmail
  // isLength
} from "~/validator/validationRules";
import category from "~/mixins/category";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPasswordRequest",
  mixins: [category],
  data() {
    return {
      email: "",
      valid: true,
      loading: false,
      lazy: true,
      //requiredRules: [requiredValue("Digitare l'email")],
      emailRules: [requiredValue("Digitare l'email"), isEmail()],
      // fidelityRules: [
      //   isLength(13, "La ZONA Card deve essere lunga 13 caratteri")
      // ],
      response: {}
    };
  },
  methods: {
    async handleResetPasswordRequest(e) {
      let _this = this;
      e.preventDefault();
      let mail = _this.email.match(/\S+@\S+\.\S/) ? _this.email : undefined;
      let fidelity = _this.email.match(/^.[0-9]*$/) ? _this.email : undefined;
      _this.response = {};
      if (_this.valid) {
        _this.loading = true;
        let res = null;
        try {
          res = await aliRegistrationService.getResetPasswordToken(
            mail,
            undefined,
            fidelity
          );
          if (res.response?.status == 2) {
            _this.$router.push({
              name: "ResetPasswordConfirm",
              params: { message: res.response, mail: mail, login: fidelity }
            });
          } else {
            _this.response = res.response;
          }
        } catch (err) {
          console.log(err);
        } finally {
          _this.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
      this.showForm = true;
    }
  }
};
</script>
